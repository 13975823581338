import React, { useEffect } from 'react';
import { useAppConfig } from '@hooks/appConfig';
import Loading from '../Loading';

const RequireLogin: React.FC = ({ children }) => {
  const {
    user = { individualId: '', isLoggedIn: false },
    login,
  } = useAppConfig();

  useEffect(() => {
    if (!user?.isLoggedIn) {
      login(true);
    }
  }, [user?.isLoggedIn]);

  if (!user?.isLoggedIn) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default RequireLogin;
